import React, { useState } from "react";
import {
  Flex, 
  Heading
} from "@chakra-ui/core"
import Img from "gatsby-image";
import { Link, useStaticQuery, graphql } from 'gatsby';
import { normalizePath } from "../utils/get-url-path";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Footer = (props) => {
  // write query here

  const wpData = useStaticQuery(graphql`
    query MyQuery {
      footerData: wpPage(title: {eq: "footer-meta"}) {
        footerMeta {
          copyright
          footerMenuTitle
          logoImage {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      footerMenu: wpMenu(locations: {eq: FOOTER}) {
        menuItems {
          nodes {
            label
            url
            parentId
            connectedNode {
              node {                
                  uri
              }
            }
          }
        }
      }
    }
  `);
  
  const [visitorEmail, setVisitorEmail] = useState("");
  
  const handleSubscribe = e => {
    e.preventDefault();
    console.log(`subscribing.. ${visitorEmail}`)
  }


  const { footerData, footerMenu } = wpData;

  
  return (
    <footer className="footer-container" id="footer">
      <Flex 
        direction={["column", "row"]} 
        className="footer-wrapper"
        justify="space-between"
      >
        <div className="footer-l">
          <Img 
            fluid={footerData.footerMeta.logoImage.localFile.childImageSharp.fluid}
            alt="footer-logo"
          />
        </div>
        <div className="footer-mid">
          <Heading as="h4" fontSize={["18px", null, "20px", "22px"]} className="bottom-border">Site Map</Heading>
          <Flex direction="column">
            {footerMenu.menuItems.nodes.map((menuItem, i) => {
              if (menuItem.parentId) {
                return null
              }

              const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url

              return (
                <Link
                  key={i + menuItem.url}
                  style={{ display: `block` }}
                  to={normalizePath(path)}
                  className="menu-anchor"
                >
                  {menuItem.label}
                </Link>
              )
            })}
          </Flex>
        </div>
        <div className="footer-r">
          <Heading as="h4" fontSize={["18px", null, "20px", "22px"]} className="bottom-border">Subscribe For Updates</Heading>
          <form onSubmit={e => handleSubscribe(e)}>
            <input 
            type="email"
              value={visitorEmail}
              onChange={e => setVisitorEmail(e.target.value)}
              placeholder="E-mail"
            />

            <button className="btn link-btn">Subscribe<FontAwesomeIcon icon={['fas','angle-right']}/><FontAwesomeIcon icon={['fas','angle-right']}/><FontAwesomeIcon icon={['fas','angle-right']}/></button>
          </form>
        </div>
      </Flex>
      <div className="footer-wrapper copyright">
        <p><i className="fal fa-copyright"></i> {footerData.footerMeta.copyright}</p>
      </div>
    </footer>
  );
};

export default Footer;
