import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
    breakpoints: [
      "320px", 
      "500px",
      "600px",
      "768px", 
      "960px", 
      "1200px"
    ],
    colors: {
      purple: "#964096"
    },
    styles: {
      global: {
        'h1.css-p5x3r9': {
          fontSize: ['27px', '50px', '80px', '80px'],
          lineHeight: ['22px', '45px', '65px']
        },
        'h3.css-p5x3r9': {
          fontSize: ['20px', '25px', '45px'],
          letterSpacing: ['0px', '1px', '2px']
        },
        '.banner-text-wrap': {
          justifyContent: ['center', 'flex-end', 'flex-end', 'center'],
          paddingBottom: ['unset', '50px', '25px', '0']
        },
        '.title-txt-btn h2, .composite-section h2, .philanthropy h3, .contact-content h1': {
          fontSize: ['25px', '35px', '50px'],
        },
        '.title-txt-btn p, .quote-block p': {
          fontSize: ['14px', '15px', '16px', '18px'],
        },
        'cta title-txt-btn h2': {
          textShadow: 'text-shadow: 8px 8px 41px rgb(86 86 86)',
          fontSize: ['27px', '50px', '80px', '80px']
        },
        ' .quote-block p': {
          lineHeight: ['40px', '55px']
        },
        
      },
    }
  });


// const horizontalList =  {
//   // The base styles for each part
//   baseStyle: {
//     flexDirection: "row"
//   },
//   // The size styles for each part
//   sizes: {},
//   // The variant styles for each part
//   variants: {},
//   // The default `size` or `variant` values
//   defaultProps: {},
// }


// export default {
//   // The parts of the component
//   parts: [],
//   // The base styles for each part
//   baseStyle: {},
//   // The size styles for each part
//   sizes: {},
//   // The variant styles for each part
//   variants: {},
//   // The default `size` or `variant` values
//   defaultProps: {},
// }

  export default theme;