import React from "react";
import { Box } from "@chakra-ui/core";
import Header from "./header";
import Footer from './footer'
import "../assets/style.css";
import "../assets/css/_colors.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleRight, faBars, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { ChakraProvider } from "@chakra-ui/react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Theme from './styles/theme';
import { Helmet } from "react-helmet";


library.add(faAngleRight);
library.add(faBars);
library.add(faTimesCircle);

const Layout = ({ children }) => {

  return(
  <ChakraProvider theme={Theme}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Alpha Phi Delta | Rutgers Univeristy</title>
    </Helmet>
    <div style={{ 
      display: 'flex', flexDirection: 'column'
    }} className="no-mar">
      <Box mb={10} mt={20} className="ignore" marginTop="unset" minH="120px" marginBottom="unset">
        <Header />
      </Box>

      <Box mb={100}>{children}</Box>
      <Footer />
    </div>
  </ChakraProvider>
)}

export default Layout
