import React from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import { Menu, Button, Grid, Box } from "@chakra-ui/core"
import { normalizePath } from "../utils/get-url-path"

export default (props) => {
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "main-menu" }) {
        name
        menuItems {
          nodes {
            label
            url
            parentId
            connectedNode {
              node {
                
                  uri
                
              }
            }
          }
        }
      }
    }
  `)

  const {closeMenu} = props;
  

  return !!wpMenu && !!wpMenu.menuItems && !!wpMenu.menuItems.nodes ? (
    <Box mb={10} style={{ maxWidth: `100%` }}>
      <Menu>
        <Grid autoFlow="column">
          {wpMenu.menuItems.nodes.map((menuItem, i) => {
            if (menuItem.parentId) {
              return null
            }

            const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url

            return (
              <Link
                key={i + menuItem.url}
                style={{ display: `block` }}
                to={normalizePath(path)}
                className="menu-anchor"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  const path = e.nativeEvent.toElement.attributes.href.value;

                  closeMenu();
                  
                  console.log("path",path);
                  setTimeout(() => {
                    navigate(path);
                  },200)
                }}
              >
                {menuItem.label}
              </Link>
            )
          })}
        </Grid>
      </Menu>
    </Box>
  ) : null
}
