import React,{ useState } from "react"
import { Link, navigate } from "gatsby";
import Menu from './menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LogoLight = require('../assets/svg/logo_light_text.svg');



export default () => {
  const openMenu = () => {
    const menuContainer = document.getElementsByClassName('nav-menu-container')[0];

    menuContainer.classList.add("show");

    setTimeout(() => {
      menuContainer.classList.add('split');
    },150)
  }

  const closeMenu = () => {
    const menuContainer = document.getElementsByClassName('nav-menu-container')[0];

    menuContainer.classList.remove("split");
    
    setTimeout(() => {
      menuContainer.classList.remove('show');
    },150)

  }
  
  return (
  <header className="header-container" id="header-container">
      <div className="header-wrapper row-flex">
        <div className="header-left">
          <Link to="/">
            <LogoLight />
          </Link>
        </div>
        <div className="header-right">
          <span 
            className="nav-btn" 
            onClick={openMenu}
          >
            <FontAwesomeIcon icon={['fas','bars']}/>
          </span>
          
        </div>
      </div>
      <div className="nav-menu-container" onClick={closeMenu}>
        <div 
        className={`menu-container show`}
        onClick={e => e.stopPropagation()}
        >
          <span 
            className="nav-btn"
          onClick={closeMenu}>
            <FontAwesomeIcon icon={['fas', 'times-circle']} />
          </span>
          <div className="logo-wrapper">
            <LogoLight />
          </div>
          <Menu closeMenu={closeMenu} />
        </div>
      </div>
    </header>
  )
}
